@import 'styles/colors';

.root {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  padding: 0 16px;
  // border:  solid 1px $secondary-border;
  // border-radius: 4px;
  height: 40px;
  background: $white;
}

.numberSelect {
  font-size: 16px;
  cursor: pointer;
  min-width: 80px;

  [class$='-control'] {
   border: 0 !important;
    box-shadow: none;
    height: 36px;
  }

  [class$='-menu'] {
    top: auto;
    bottom: 100%;
  }
}

.showLabel {
  font-size: 16px;
  margin-right: 10px;
  color: rgba(17, 15, 36, 0.4);
  width: 120px;
}
