@import 'styles/colors';
@import 'styles/fonts';

.passwordStrengthMeter {
  text-align: left;
  position: relative;
  display: block;
  min-height: 25px;
}

.passwordStrengthMeterProgress {
  -webkit-appearance: none;
  appearance: none;
  width: 99.6%;
  height: 4px;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  margin: auto;
}

.passwordStrengthMeterProgress::-webkit-progress-bar {
  background-color: #eee;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.passwordStrengthMeter .passwordStrengthMeterLabel {
    font-size: 14px !important;
    color: $red;
    margin-top: 4px;
    position: absolute;
}

.passwordStrengthMeter .passwordStrengthMeterLabel.strengthWeak {
    color: $red !important;
}

.passwordStrengthMeter .passwordStrengthMeterLabel.strengthFair {
    color: #FF7127;
}

.passwordStrengthMeter .passwordStrengthMeterLabel.strengthStrong {
    color: #00c22d;
}

.passwordStrengthMeter .passwordStrengthMeterLabel.strengthGood {
    color: #1256c9;
}

.passwordStrengthMeter .passwordStrengthMeterLabel.strengthDefault {
    color: #1256c9;
}

.passwordStrengthMeterProgress::-webkit-progress-value {

  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 0px;
  border-top-right-radius: 6px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
  transition: width 0.5s;
}

.strengthWeak::-webkit-progress-value {
  background-color: rgb(255, 0, 0);
}
.strengthFair::-webkit-progress-value {
  background-color: #FF7127;
}
.strengthGood::-webkit-progress-value {
  background-color: #1256c9;
}
.strengthStrong::-webkit-progress-value {
  background-color: #00c22d;
  border-top-right-radius: 0px;
}

@keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}