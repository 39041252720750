@import 'styles/colors';
@import 'styles/fonts';

.root {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoContainer {
  margin: 55px 0 45px;
  width: 224px;
  height: auto;
}

.logoImg {
  height: 100%;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $white;
  width: 614px;
  min-height: 600px;
  padding: 56px;
  border-radius: 17px;
  box-shadow: 0 24px 36px 0 rgba(196, 203, 214, 0.4);
  border: solid 1px rgba(112, 112, 112, 0.08);

  &.logout {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.wizardIndicatorWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  gap: 10px;

  h6 {
    font-weight: 700;
    color: $black;
  }

  .wizardIndicatorList {
    display: flex;
    gap: 10px;

    .wizardIndicatorItem {
      background-color: $light-grey-0;
      height: 4px;
      border-radius: 2px;
      width: 74px;

      &.clickable {
        cursor: pointer;
      }

      &.active {
        background-color: $blue-normal;
      }
    }
  }
}

.heading {
  @include heading1;
  text-align: center;
  line-height: 1.3;
}

.subHeading {
  @include text3;
  margin-top: 16px;
  text-align: center;
  color: $font-grey;
}

.formHolder {
  flex-grow: 1;
  width: 100%;
  margin-top: 36px;
}

.logoutContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  // margin-top: 25%;
  align-items: center;
}

.successIcon {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
  fill: $green;
}

.infoMessage {
  text-align: center;
  background-color: $green-light;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-radius: 7px;
  gap: 5px;
  font-size: 16px;
  padding: 10px;
  color: $black;
}
