@import 'styles/colors';
@import 'styles/fonts';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.submitButtonContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
  width: 100%;
}

.errorMessage {
  @include text3;
  text-align: center;
  color: $red;
}

.errorText {
  color: $red;
  font-size: 14px;
  margin-top: 10px;
}

.submitButton {
  @include heading3;
  width: 343px;
  height: 50px;
  border-radius: 8px;
  background-color: $blue;
  color: $white;
  cursor: pointer;
  border: none;
  transition: all 0.2s;

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

}

.termsCheckbox {
  @include text3;

  > label {
    width: 100%;
    display: flex;
    cursor: pointer;

    > input {
      margin-right: 5px;
    }

    a {
      color: $blue-normal;
    }
  }
}

.termsTextWrapper {
  background-color: #fbfbfb;
  border: solid 1px $grey-light;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  max-height: calc(100vh - 500px);
  min-height: 200px;
  overflow: auto;

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  h4 {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  p {
    font-size: 14px;
    margin-bottom: 10px;

    + h3 {
      margin-top: 20px;
    }

    b {
      margin-right: 5px;
    }
  }

  ul {
    padding: 5px 0 5px 20px;
    font-size: 14px;

    li {
      margin-bottom: 5px;
    }
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.termsTextContent {

  p {
    position: relative;
    padding-left: 40px;

  }

  .listCount {
    margin-left: -40px;
    width: 36px;
    display: inline-block;
    font-size: 13px;
  }
}
